
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import QuotesService from "@/core/services/car/QuotesService";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Multiselect from "@vueform/multiselect";
import LeadDriverDetailsView from "@/components/partials/leaddriverdetails/Lead-DriverDetiails-View.vue";
import { roles } from "@/store/stateless/store";

interface addQuoteBodyParam {
  customer_id: string;
  car_year: string;
  manufacturer_id: number;
  model_id: number;
  trim_level_id: number;
  is_new: number| null;
  car_value: number;
  first_registration_date: string;
  registration_emirate: number | any;
  dob: string;
  nationality: number | null;
  first_driving_license_country: number | null;
  driving_experience: number | null;
  uae_driving_experience: number | null;
  policy_start_date: string;
  claimed_insurance: number;
  no_claim_certificate: any | null;
  claims: string;
  gcc_specification: number | null;
  personal_use: number | null;
  current_policy_active: number | null;
  fully_comprehensive: number | null;
  third_party_liability: number | null;
  existing_policy_expired: number | null;
  lead_id: string;
  driver_name: string;
}
export interface ICountryMultiselect {
  placeholder: any;
  value: any | null;
  options: any;
  searchable: boolean;
  clearOnSelect: boolean;
}
export default defineComponent({
  name: "Edit-Quote",
  props: {},
  components: {
    LeadDriverDetailsView,
  },

  computed: {},
  setup() {
    const store = useStore();
    const route = useRoute();
    let ShowLoading = ref<boolean>(true);

    let addQuoteBodyParam = ref<addQuoteBodyParam>({
      customer_id: "",
      car_year: "",
      manufacturer_id: -1,
      model_id: -1,
      trim_level_id: -1,
      is_new: 2,
      car_value: 0,
      first_registration_date: "",
      registration_emirate: 7,
      dob: "",
      nationality: null,
      first_driving_license_country: null,
      driving_experience: -1,
      uae_driving_experience: -1,
      policy_start_date: "",
      claimed_insurance: 2,
      no_claim_certificate: "1",
      claims: "",
      gcc_specification: null,
      personal_use: null,
      current_policy_active: null,
      fully_comprehensive: null,
      third_party_liability: null,
      existing_policy_expired:null,
      lead_id: "",
      driver_name: "",
    });
    let key = ref<any>();
    let editQuoteObj = computed(() => {
      return store.getters.getEditQuote;
    });
    let editDomStatus = computed(() => {
      return store.getters.getShowEditDriverDetailsStatus;
    });
    onMounted(() => {
      if(!roles.customerdetails.includes(roles.userId())) window.location.href = '/#/404'

      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Add Driver Details", ["Leads & Tasks"]);
      
    });
    Promise.all([
      QuotesService.fetchQuote({
        driver_details_id: route.params.driverDetailsId,
      }),
    ]).then((data) => {
      
      console.log("afer fetching");
      console.log(editQuoteObj.value);
      key.value = editQuoteObj.value.driver_details_id;
    });
    return {
      roles,
      key,
      editQuoteObj,
      editDomStatus,
      addQuoteBodyParam,
    };
  },
});
